import React from 'react';
import { formatPhoneNumber } from '../../utils/Utils';

import MUIDataTable from 'mui-datatables';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import makeStyles from '@mui/styles/makeStyles';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
import TicketService from 'services/TicketService';
import useAPI from 'utils/useAPI';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';
import TransactionService from 'services/TransactionService';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ListAltIcon from '@mui/icons-material/ListAlt';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EvStationIcon from '@mui/icons-material/EvStation';
import BatteryChargingIcon from '@mui/icons-material/BatteryCharging20';
import CraeteIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import SmsIcon from '@mui/icons-material/Sms';
import AddIcon from '@mui/icons-material/Add';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ResendIcon from '@mui/icons-material/Cached';

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const getMappedStatus = (ticket) => {
  const status = ticket.vehicle_status;
  if (status === 'unparked') {
    return 'Start Service';
  }
  if (status === 'parked') {
    return 'Service in Progress';
  }
  if (status === 'retrieved') {
    return 'Service Complete';
  }
  return String(status).toUpperCase();
};

export default function Tickets() {
  const api = useAPI();
  const navigate = useNavigate();
  let path = '/ticket/edit/';
  const [modal, setModal] = React.useState(false);

  // TODO: remove these setters
  const [, setVehicleStatus] = React.useState('');
  const [, setTicketId] = React.useState(0);
  const [message, setMessage] = React.useState('');
  const [bookingId, setBookingId] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [sendData, setSendData] = React.useState(null);
  const classes = useStyles();

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    TransactionService.getInvoiceLink(transaction).then((result) => {
      openInNewTab(result);
    });
  };

  const changeStatus = async (data) => {
    await TicketService.updateTicket(data);
  };

  const fetchSubscriptionBooking = () => {
    TicketService.getTickets().then((res) => {
      let d = transform(res.results);
      setData(d);
    });
  };
  React.useEffect(() => {
    TicketService.init(api);
    TransactionService.init(api);
    fetchSubscriptionBooking();
  }, [api]);

  const filterString = (value) => {
    return value ? value : '-';
  };

  const refundTicket = async (transaction) => {
    TicketService.refundTicket(transaction).then(() => {
      fetchSubscriptionBooking();
    });
  };

  const handleMessage = (event) => {
    // console.log(event.target.value);
    setMessage(event.target.value);
  };

  const sendSMS = () => {
    console.log('msg', bookingId, message);
    TicketService.sendSMS(bookingId, message).then((res) => {
      console.log('!23', res);
      setMessage('');
    });
  };

  function transform(session) {
    let transformed_ticket = session;
    transformed_ticket = transformed_ticket.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.name),
        phone: formatPhoneNumber(ses.phone),
        vehicle_type: ses.vehicle_type,
        plate: filterString(ses.plate),
        color: filterString(ses.color),
        vehicle_exit: filterString(ses.vehicle_exit),
        vehicle_damage: filterString(ses.vehicle_damage),
        payment_status: (
          <>
            {' '}
            <div display='flex'>
              <FormControlLabel
                control={
                  <Switch
                    checked={ses.payment_status === 'paid'}
                    // checked={true}
                    onChange={(e) => {
                      changeStatus({
                        id: ses.id,
                        payment_status: e.target.checked ? 'paid' : 'unpaid',
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                    }}
                    name='payment_status'
                  />
                  // String(filterString(ses.payment_status)).toUpperCase()
                }
                label={
                  ses.payment_status ? String(capitalizeFirstLetter(ses.payment_status)) : 'NA'
                }
              />
            </div>
          </>
        ),
        payment_type: String(filterString(ses.payment_type)).toUpperCase(),
        ticketUrl: ses.ticketUrl,
        total_amount: ses.total_amount ? ses.total_amount : 'NA',
        vehicle_status: (
          <>
            {getMappedStatus(ses) + ' '}
            <div display='flex'>
              {ses.vehicle_status === 'unparked' && (
                <Tooltip title='Service' aria-label=''>
                  <IconButton
                    onClick={() => {
                      // setModal(false);
                      setVehicleStatus('parked');
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: 'parked',
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus('');
                      setTicketId(0);
                    }}
                    size='large'
                  >
                    <ArrowUpwardIcon color='primary' />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status === 'parked' && (
                <Tooltip title='Retrieve' aria-label=''>
                  <IconButton
                    onClick={() => {
                      setVehicleStatus('retrieved');
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: 'retrieved',
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus('');
                      setTicketId(0);
                    }}
                    size='large'
                  >
                    <ArrowDownwardIcon style={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status === 'uncharged' && (
                <Tooltip title='Charge' aria-label=''>
                  <IconButton
                    onClick={() => {
                      setVehicleStatus('charging');
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: 'charging',
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus('');
                      setTicketId(0);
                    }}
                    size='large'
                  >
                    <BatteryChargingIcon color='primary' />
                  </IconButton>
                </Tooltip>
              )}
              {ses.vehicle_status === 'charging' && (
                <Tooltip title='Charged' aria-label=''>
                  <IconButton
                    onClick={() => {
                      setVehicleStatus('charged');
                      setTicketId(ses.id);
                      changeStatus({
                        id: ses.id,
                        vehicle_status: 'charged',
                      }).then(() => {
                        fetchSubscriptionBooking();
                      });
                      setVehicleStatus('');
                      setTicketId(0);
                    }}
                    size='large'
                  >
                    <EvStationIcon style={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </>
        ),
        actions: (
          <>
            <div display='flex'>
              <Tooltip title='Update Ticket' aria-label=''>
                <IconButton
                  onClick={() => {
                    navigate(path + ses.id);
                  }}
                  size='large'
                >
                  <CraeteIcon style={{ color: 'brown' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Open Ticket' aria-label=''>
                <IconButton
                  onClick={() => {
                    window.open(ses.ticketUrl);
                  }}
                  size='large'
                >
                  <LinkIcon style={{ color: 'blue' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Send Message' aria-label=''>
                <IconButton
                  onClick={() => {
                    setBookingId(ses.id);
                    setModal(true);
                  }}
                  size='large'
                >
                  <SmsIcon style={{ color: '#0C3C78' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div display='flex'>
              <Tooltip title='Resend Ticket' aria-label=''>
                <IconButton
                  onClick={() => {
                    TicketService.resendSMS(ses.id).catch();
                  }}
                  size='large'
                >
                  <ResendIcon style={{ color: '#0C3C78' }} />
                </IconButton>
              </Tooltip>
              {ses.vehicle_status === 'retrieved' && (
                <>
                  <Tooltip title='View Invoice' aria-label=''>
                    <IconButton
                      onClick={() => {
                        window.open(`${ses.ticketUrl}/receipt`);
                      }}
                      size='large'
                    >
                      <ReceiptIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {ses.stripe_payment_intent_id && (
                <>
                  <Tooltip title='View Invoice' aria-label=''>
                    <IconButton
                      onClick={() => {
                        viewInvoice(ses.stripe_payment_intent_id);
                      }}
                      size='large'
                    >
                      <ReceiptIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={ses.stripe_refund_id ? 'Refunded' : 'Make Refund'} aria-label=''>
                    <span>
                      <IconButton
                        disabled={ses.stripe_refund_id}
                        onClick={() => {
                          setOpen(true);
                          setSendData({
                            id: ses.id,
                            payment_intent: ses.stripe_payment_intent_id,
                            ticketRefund: true,
                          });
                        }}
                        size='large'
                      >
                        <AssignmentReturnIcon
                          color={ses.stripe_refund_id ? 'secondary' : 'primary'}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          </>
        ),
      };
    });
    return transformed_ticket;
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const columns = [
    {
      label: 'Actions',
      name: 'actions',
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Phone',
      name: 'phone',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.replace(/[^\d]/g, '');
            let val2 = obj2.data.replace(/[^\d]/g, '');
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      label: 'Vehicle Type',
      name: 'vehicle_type',
    },
    {
      label: 'Plate',
      name: 'plate',
    },
    {
      label: 'Color',
      name: 'color',
    },
    {
      label: 'Vehicle Status',
      name: 'vehicle_status',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.props.children[0];
            let val2 = obj2.data.props.children[0];
            return (val1.length < val2.length ? -1 : 1) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      label: 'Total Amount ($)',
      name: 'total_amount',
    },
    {
      label: 'Payment Status',
      name: 'payment_status',
    },
    {
      label: 'Payment Type',
      name: 'payment_type',
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customToolbar: () => {
      return (
        <Tooltip title='Add Ticket' aria-label=''>
          <IconButton
            onClick={() => {
              navigate('/ticket/create');
            }}
            size='large'
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <ListAltIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Tickets</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}

          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setBookingId(0);
            }}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title' className={classes.modalHeader}>
              <IconButton
                className={classes.modalCloseButton}
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => {
                  setModal(false);
                  setBookingId(0);
                }}
                size='large'
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <div className={classes.modalTitle}>Send SMS</div>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              <TextField
                id='outlined-multiline-static'
                label='Message'
                multiline
                rows={4}
                value={message}
                fullWidth
                onChange={handleMessage}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              <Button
                onClick={() => {
                  setModal(false);
                  setMessage('');
                  setBookingId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setModal(false);
                  console.log(message, bookingId);
                  sendSMS();
                  setMessage('');
                  setBookingId(0);
                }}
                color='primary'
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Confirm Refund Transaction'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Are you sure you want to refund this transaction?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  refundTicket(sendData);
                  setOpen(false);
                }}
                color='primary'
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
