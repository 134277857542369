import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import UserService from 'services/UserService';
import useAPI from 'utils/useAPI';
import { formatPhoneNumber } from 'utils/Utils';
import {Autocomplete, TextField} from '@mui/material';

const INSTRUCTIONS = 'Start typing user name, email or phone';

export default function UserAutocomplete({onUserSelected, onUserCleared, label = 'User*', initialUser = null}) {
  const api = useAPI();

  const [userInput, setUserInput] = useState('');
  const [usersLoading, setUsersLoading] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [userInputLabel, setUserInputLabel] = useState(INSTRUCTIONS);
  const [shrinkLabel, setShrinkLabel] = useState(false);

  useEffect(() => {
    UserService.init(api);
  }, [api]);

  useEffect(() => {
    if (initialUser) {
      setSelectedUser(userTransformer(initialUser));
    }
  }, [initialUser]);

  useEffect(() => {
    if (userInput.trim().length > 2) {
      setUsersLoading(true);
      fetchUserOptionsForUserInput();
    }
  }, [userInput]);

  const fetchUserOptionsForUserInput = async () => {
    const response = await UserService.getUsers({
      search: userInput.trim(),
      filters: [],
      orderBy: null,
      orderDirection: null,
      page: 1,
      pageSize: 50,
    });
    setSelectedUser(null);
    setUserOptions(response.results.map(userTransformer));
    setUsersLoading(false);
  };

  const reinitializeInput = () => {
    setUserInput('');
    setUserOptions([]);
    setSelectedUser(null);
  }

  const userTransformer = (user) => {
    let label = `${user.name} | ${user.email}`;
    if (user.phone) {
      label += ` | ${formatPhoneNumber(user.phone)}`;
    }
    return {
      ...user,
      label,
    }
  }

  useEffect(() => {
    if (selectedUser || shrinkLabel) {
      setUserInputLabel(label);
    } else {
      setUserInputLabel(INSTRUCTIONS);
    }
    if (selectedUser) {
      onUserSelected(selectedUser);
    }
  }, [selectedUser, shrinkLabel]);

  const handleFocus = () => {
    setShrinkLabel(true);
  };

  const handleBlur = () => {
    if (!selectedUser) {
      setShrinkLabel(false);
    }
  };

  return (
    <Autocomplete
      value={selectedUser}
      onChange={(event, newValue, reason) => {
        if ('clear' === reason) {
          reinitializeInput();
          onUserCleared();
        } else {
          setSelectedUser(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(user) => user.label}
      options={userOptions}
      loading={usersLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={userInputLabel}
          variant='outlined'
          onChange={(e) => setUserInput(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}

        />
      )}
    />

  )
}

UserAutocomplete.propTypes = {
  onUserSelected: PropTypes.func.isRequired,
  onUserCleared: PropTypes.func.isRequired,
  label: PropTypes.string,
  initialUser: PropTypes.object,
}
