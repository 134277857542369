import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  MenuItem,
  Select,
  InputAdornment,
  Typography,
} from '@mui/material';

import 'react-international-phone/style.css';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { isValidPhoneNumber, isValidInternationalPrefix } from '../../utils/Utils';

export const InternationalPhone = ({ value, onChange, disabled = false, ...restProps }) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'us',
      value: value || '',
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });

  const isPhoneValid = value === '' || isValidPhoneNumber(value) || isValidInternationalPrefix(value);
  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: '10px' }}>
      <TextField
        style={{minWidth: '220px'}}
        variant="outlined"
        label="Phone number"
        color={isPhoneValid ? 'primary' : 'error'}
        placeholder="Phone number"
        value={inputValue}
        disabled={disabled}
        onChange={handlePhoneValueChange}
        type="tel"
        inputRef={inputRef}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ marginRight: '2px', marginLeft: '-8px' }}
              >
                <Select
                  MenuProps={{
                    style: {
                      height: '300px',
                      width: '360px',
                      top: '10px',
                      left: '-34px',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  sx={{
                    width: 'max-content',
                    // Remove default outline (display only on focus)
                    fieldset: {
                      display: 'none',
                    },
                    '&.Mui-focused:has(div[aria-expanded="false"])': {
                      fieldset: {
                        display: 'block',
                      },
                    },
                    // Update default spacing
                    '.MuiSelect-select': {
                      padding: '8px',
                      paddingRight: '24px !important',
                    },
                    svg: {
                      right: 0,
                    },
                  }}
                  value={country.iso2}
                  onChange={(e) => setCountry(e.target.value)}
                  renderValue={(value) => (
                    <FlagImage iso2={value} style={{ display: 'flex' }} />
                  )}
                >
                  {defaultCountries.map((c) => {
                    const country = parseCountry(c);
                    return (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        <FlagImage
                          iso2={country.iso2}
                          style={{ marginRight: '8px' }}
                        />
                        <Typography marginRight="8px">{country.name}</Typography>
                        <Typography color="gray">+{country.dialCode}</Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputAdornment>
            ),
          }
        }}
        {...restProps}
      />
      {!isPhoneValid &&
        <Typography color={'error'} sx={{fontSize: '14px'}}>Invalid number</Typography>
      }
    </div>
  );
};
InternationalPhone.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
