import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();

export function convertApiError(error) {
  console.log('API error', error);
  let errMsg = error?.response?.data?.error;
  if (errMsg && typeof errMsg === 'string') {
    if (errMsg.startsWith('Error: ')) {
      errMsg = errMsg.substring(7);
    }
    return errMsg;
  } else {
    errMsg = error?.response?.data?.message;
    if (errMsg && typeof errMsg === 'string') {
      if (errMsg.startsWith('Error: ')) {
        errMsg = errMsg.substring(7);
      }
      return errMsg;
    }
  }
  return error;
}

export function getReadableNumericTimestamp() {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
  return `${year}${month}${day}_${hours}${minutes}${seconds}${milliseconds}`;
}

export function formatUTCDateInLocalTime(utcString, dateFirst = false) {
  if (!utcString) {
    return null;
  }
  const date = new Date(utcString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().slice(2);
  let period = 'AM';
  let hour12Format = hours;
  if (hours > 12) {
    hour12Format = hours - 12;
    period = 'PM';
  } else if (hours === 0) {
    hour12Format = 12;
  } else if (hours === 12) {
    period = 'PM';
  }
  return dateFirst ? `${month}/${day}/${year}, ${hour12Format}:${minutes.toString().padStart(2, '0')} ${period}` :
    `${hour12Format}:${minutes.toString().padStart(2, '0')} ${period}, ${month}/${day}/${year}`;
}

/**
 * Generate item count phrase, e.g. "1 car", "No cars" (or "no cars"), "2 cars", etc.
 * @param count the number of items
 * @param singularItem the singular form of the item
 * @param pluralItem the plural form of the item
 * @param capitalize whether or not to capitalize "no" if the count is 0.
 * @return {string} the phase describing the number of items
 */
export function itemCount(count, singularItem, pluralItem, capitalize = false) {
  return count === 0 ? `${capitalize ? 'No' : 'no'} ${pluralItem}` :
    count === 1 ? `1 ${singularItem}` :
      `${count} ${pluralItem}`;
}

export function wordCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)?.toLowerCase();
}

/**
 * Convert an international phone number in E.164 format (e.g. "+18005551212") to US national format if a US number.
 * Otherwise, convert to an international format, e.g. +XX YYY ZZZ ZZZZ
 * @param phoneNumber a phone number in E.164 format
 */
export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber);
    const regionCode = phoneUtil.getRegionCodeForNumber(parsedNumber)
    if (regionCode === 'US') {
      return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL); // (nnn) nnn-nnnn
    } else {
      return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL); // +XX YYY ZZZ ZZZZ
    }
  } catch (error) {
    console.error(`Error parsing phone number ${phoneNumber}`, error);
    return phoneNumber;
  }
}

export const isValidPhoneNumber = (phone) => {
  try {
    return phone && phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

// Function to check if a string is a valid international prefix, i.e. +NN, where NN is a valid country code
export const isValidInternationalPrefix = (prefix) => {
  try {
    if (!prefix?.startsWith('+')) {
      return false;
    }
    const numericPrefix = prefix.slice(1);
    const regionCode = phoneUtil.getRegionCodeForCountryCode(Number(numericPrefix));
    return !!regionCode && regionCode !== 'ZZ';
  } catch (error) {
    console.error(`Error parsing international prefix ${prefix}`, error);
    return false;
  }
};

export const States = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
]

export const StateNamesToAbbreviations = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "District Of Columbia": "DC",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY"
};

