import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import { styled } from '@mui/material/styles';

import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ScreenLockLandscapeIcon from '@mui/icons-material/ScreenLockLandscape';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RepartitionIcon from '@mui/icons-material/Repartition';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import WarningIcon from '@mui/icons-material/Warning';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MemoryIcon from '@mui/icons-material/Memory';

import CarouselService from 'services/CarouselService';
import CameraService from 'services/CameraService';
const Utils = require('../../utils/Utils');
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

import Button from 'components/CustomButtons/Button.js';
import useAPI from 'utils/useAPI';
import {useNavigate} from 'react-router-dom';
import MaterialTable, {MTableToolbar} from 'material-table';
import {JsonViewer} from '@textea/json-viewer';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
import makeStyles from '@mui/styles/makeStyles';

// Removed Show Carousel QR Code and Delete Carousel functions
//import LinkIcon from '@mui/icons-material/Link';
//import DeleteIcon from '@mui/icons-material/Delete';

import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Tooltip,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  Close,
  Check,
} from '@mui/icons-material';

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

class VirtualCarButton {
  constructor(carouselId, isCarOnParkingPallet, showStateModal) {
    this.carouselId = carouselId;
    this.isCarOnParkingPallet = isCarOnParkingPallet;
    this.showStateModal = showStateModal;
  }

  get buttonText() {
    return this.isCarOnParkingPallet ? 'Clear any virtual car' : 'Simulate virtual car';
  }

  handleClick = () => {
    CarouselService.simulateCarInBay(this.carouselId, !this.isCarOnParkingPallet)
      .then(() => this.showStateModal(this.carouselId)).catch(err => alert(err));
  }
}

const ServiceStatusIcon = ({ status, onRestart }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleConfirm = () => {
    setDialogOpen(false);
    onRestart();
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Tooltip title={status === 'healthy' ? 'healthy' : 'unhealthy'}>
        <IconButton onClick={handleClick}>
          <div
            style={{
              backgroundColor: status === 'healthy' ? 'green' : 'red',
              color: 'white',
              padding: '4px 8px',
              borderRadius: '4px',
              fontSize: '0.875rem',
            }}
          >
            Carousel API
          </div>
        </IconButton>
      </Tooltip>

      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>Are you sure you want to restart the Carousel API?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color='danger'>
            Restart
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ServiceStatusIcon.propTypes = {
  status: PropTypes.oneOf(['healthy', 'unhealthy']).isRequired,
  onRestart: PropTypes.func.isRequired,
};

export default function Carousels() {
  const api = useAPI();
  const navigate = useNavigate();
  const classes = useStyles();
  const { hasPermission } = useContext(AuthContext);
  const isParkingSupport = hasPermission(Permissions.PARKING_SUPPORT);
  const isSysAdmin = hasPermission(Permissions.SYSTEM_ADMINISTRATION);

  const [statisticsModal, setStatisticsModal] = React.useState(false);
  const [statisticsTitle, setStatisticsTitle] = React.useState('');
  const [jsonObject, setJsonObject] = React.useState({});
  const [inclGateColumn, setInclGateColumn] = React.useState(false);
  const [cameraStreamToken, setCameraStreamToken] = React.useState(null);
  const [carouselApiStatus, setCarouselApiStatus] = useState('healthy');

  React.useEffect(() => {
    if (!hasPermission(Permissions.VIEW_CAROUSELS)) {
      navigate('/');
    }
    CarouselService.init(api);
    CameraService.init(api);
  }, [api]);

  const handleRestartCarouselApi = async () => {
    try {
      const resp = await CarouselService.restartCarouselApi();
      alert(resp.message)
    } catch (err) {
      alert(err);
    }
  };

  const showStateModal = (carouselId) => {
    CarouselService.getCarouselState(carouselId).then((response) => {
      const { state, isCarOnParkingPallet } = response;
      setJsonObject(state);
      setStatisticsTitle('State Data');
      setStatisticsModal(true);
      setVirtualCarBtn(new VirtualCarButton(carouselId, isCarOnParkingPallet, showStateModal))
    }).catch(e => alert(e));
  }

  const showLocationModal = (carouselId) => {
    CarouselService.getCarouselPalletData(carouselId).then((response) => {
      setJsonObject(response);
      setStatisticsTitle('Pallet Data');
      setStatisticsModal(true);
    }).catch(e => alert(e));
  }

  // null (don't show button), true (show button to simulate car), false (show button to remove virtual car)
  const [virtualCarBtn, setVirtualCarBtn] = React.useState(null);
  const tableRef = React.createRef();

  //
  const [modalCarouselId, setModalCarouselId] = React.useState(null);
  const [modalCarouselNickname, setModalCarouselNickname] = React.useState('');

  // Set Ground Pallet/Call Pallet modal dialog
  const GroundPalletModalModes = {
    INIT_GROUND_PALLET: 'INIT_GROUND_PALLET',
    CALL_PALLET: 'CALL_PALLET',
  }
  const [showGroundPalletModal, setShowGroundPalletModal] = React.useState(false);
  const [groundPalletModalMode, setGroundPalletModalMode] = React.useState(GroundPalletModalModes.INIT_GROUND_PALLET);
  const [groundPalletNo, setGroundPalletNo] = React.useState(0);
  const [groundPalletPosition, setGroundPalletPosition] = React.useState(null);

  const openGroundPalletModal = (rowData, mode) => {
    setModalCarouselId(rowData.id);
    setModalCarouselNickname(rowData.nickname);
    setGroundPalletModalMode(mode);
    if (rowData.is_tandem) {
      setGroundPalletPosition('entrance'); // non-null value will show option in modal
    }
    setShowGroundPalletModal(true);
  }

  const closeGroundPalletModal = () => {
    setShowGroundPalletModal(false);
    setGroundPalletNo(0);
    setModalCarouselId(null);
    setModalCarouselNickname('');
    setGroundPalletPosition(null);
  };

  const changeGroundPalletNo = (event) => {
    const val = event.target.value;
    if (/^\d{0,2}$/.test(val)) {
      setGroundPalletNo(val);
    }
  }

  const submitGroundPalletNo = () => {
    const carouselName = `${modalCarouselNickname}${groundPalletPosition ? ' ' + groundPalletPosition : ''}`;
    const isInitMode = groundPalletModalMode === GroundPalletModalModes.INIT_GROUND_PALLET;
    const confirmText = isInitMode ?
      `Set ground pallet to ${groundPalletNo} on ${carouselName}?` :
      `Call pallet ${groundPalletNo} to ground on ${carouselName}?`;
    if (confirm(confirmText)) {
      if (isInitMode) {
        CarouselService.setGroundPallet(modalCarouselId, groundPalletPosition || 'entrance', groundPalletNo).then(res => {
          if (!res.success) {
            alert(res.message);
          } else {
            alert('Success');
          }
        }).catch((err) => {
          alert(err);
        })
      } else {
        CarouselService.callPallet(modalCarouselId, groundPalletPosition || 'entrance', groundPalletNo).then(res => {
          if (!res.success) {
            alert(res.message);
          } else {
            alert('Success');
          }
        }).catch((err) => {
          alert(err);
        })
      }
    }
    closeGroundPalletModal();
  }

  // Parking command
  const [showParkingCommandModal, setShowParkingCommandModal] = React.useState(false);

  // Support panel
  const [isSupportPanelOpen, setSupportPanelOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const handleOpenSupportPanel = (rowData) => {
    if (rowData.camera_id) {
      CameraService.getCameraStreamToken().then((token) => {
        setCameraStreamToken(token);
      })
    }
    setCurrentRowData(rowData);
    setSupportPanelOpen(true);
  };

  const handleCloseSupportPanel = () => {
    setCurrentRowData(null);
    setSupportPanelOpen(false);
    if (cameraStreamToken) {
      setCameraStreamToken(null);
      CameraService.closeCamera();
    }
  };

  // Unhealthy condition / critical alert
  const [showCriticalAlertPanel, setShowCriticalALertPanel] = React.useState(false);

  const handleOpenCriticalAlert = (rowData) => {
    setCurrentRowData(rowData);
    setShowCriticalALertPanel(true);
  }

  const handleCloseCriticalAlert = () => {
    setCurrentRowData(null);
    setShowCriticalALertPanel(false);
  }

  const runHealthCheck = (carouselId, cleanupFn) => {
    if (carouselId) {
      CarouselService.runHealthCheck(carouselId).then((res) => {
        alert(`Health check ${res.success ? 'passed' : 'failed'}`);
        onRefreshData();
        if (cleanupFn) {
          cleanupFn();
        }
      }).catch((err) => {
        alert(err);
        onRefreshData();
        if (cleanupFn) {
          cleanupFn();
        }
      })
    }
  }

  const closeStatsModal = () => {
    setStatisticsModal(false);
    setVirtualCarBtn(null);
  }

  const onResetHmi = async (carouselId, nickname) => {
    if (confirm(`Are you sure you want to reset the HMI on ${nickname}?`)) {
      try {
        await CarouselService.resetHmi(carouselId);
        alert('Reset sent. It may take up to a minute for the alert to clear.');
      } catch (err) {
        alert(err);
      }
    }
  }

  const onClearAlarm = async (carouselId) => {
    if (confirm(`CLEARING THE ALARM COULD CAUSE THE CAROUSEL TO CONTINUE A MOVEMENT THAT WAS IN PROGRESS.`)) {
      try {
        await CarouselService.clearAlarm(carouselId);
        alert('Command sent. It may take up to a minute for the alarm to clear.');
      } catch (err) {
        alert(err);
      }
    }
  }

  const onMoveGate = async (carouselId, nickname, open, closePanel) => {
    if (confirm(`Are you sure you want to ${open ? 'open' : 'close'} the gate on ${nickname}?`)) {
      try {
        await CarouselService.moveGate(carouselId, open);
        alert('Command sent. This may take up to a minute.');
      } catch (err) {
        alert(err);
      }
      closePanel();
    }
  }

  const onSendParkingCommand = async (command, pin) => {
    const confirmText = pin ? `Are you sure you want to send the ${command} command with pin ${pin} to ${modalCarouselNickname}?` :
      `Are you sure you want to send the ${command} command to ${modalCarouselNickname}?`;
    if (confirm(confirmText)) {
      try {
        const result = await CarouselService.sendParkingCommand(modalCarouselId, command, pin);
        alert(JSON.stringify(result));
        onRefreshData();
      } catch (err) {
        alert(err);
      }
    }
  }

  const openSendParkingCommandModal = async (carouselId, nickname) => {
    setModalCarouselId(carouselId);
    setModalCarouselNickname(nickname);
    setShowParkingCommandModal(true);
  }

  const closeSendParkingCommandModal = () => {
    setShowParkingCommandModal(false);
    setModalCarouselId(null);
    setModalCarouselNickname('');
  };

  const onReadPin = async (carouselId, nickname) => {
    try {
      const plcPin = await CarouselService.readPin(carouselId);
      alert(`PLC PIN on ${nickname} is ${plcPin}`);
    } catch (err) {
      alert(err);
    }
  }

  const onRefreshAttributes = async (carouselId, nickname) => {
    if (confirm(`Are you sure you want to refresh cached attributes on ${nickname}?`)) {
      try {
        await CarouselService.refreshAttributes(carouselId);
        alert('Attributes refreshed')
        onRefreshData();
      } catch (err) {
        alert(err);
      }
    }
  }

  /*
  const onDeleteCarousel = (closePanel) => async () => {
    if (confirm(`Are you sure you want to delete ${currentRowData.nickname}?`)) {
      CarouselService.deleteCarousel(currentRowData.id).then(() => {
        closePanel();
        onRefreshData();
      }).catch((err) => {
        closePanel();
        if (typeof err === 'string' && err.indexOf('ER_ROW_IS_REFERENCED') >= 0) {
          console.log('Delete carousel returned error', err);
          alert('Unable to delete carousel -- make sure no Devices, Pins or Parking Sessions exist.');
        } else {
          alert(err);
        }
      })
    }} */

  const onRefreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  }

  function transform(carousels) {
    return carousels.map((carousel) => {
      return {
        id: carousel.id,
        ip_address: carousel.ip_address,
        nickname: carousel.nickname,
        description: carousel.description,
        location: carousel.location.name,
        is_ev: !!carousel.is_ev,
        is_suv: !!carousel.is_suv,
        is_valet: !!carousel.is_valet,
        is_reservation_only: !!carousel.is_reservation_only,
        availableSpaces: carousel.available_spaces,
        occupiedSpaces: Number.isInteger(carousel.total_spaces) && Number.isInteger(carousel.available_spaces) ?
          carousel.total_spaces - carousel.available_spaces : undefined,
        totalSpaces: carousel.total_spaces,
        assignedSpaces: carousel.assigned_spaces,
        is_tandem: !!carousel.is_tandem,
        hasGate: !!carousel.has_gate,
        gateState: carousel.has_gate ?
          (!carousel.gate_state ? 'UNKNOWN' : carousel.gate_state.startsWith('UNKNOWN') ? 'MOVING' : carousel.gate_state) : 'N/A',
        hmiInUse: !!CarouselService.isHmiInUse(carousel),
        alarmDetected: !!CarouselService.isAlarmDetected(carousel),
        unhealthy_condition: carousel.unhealthy_condition,
        recommended_action: carousel.recommended_action,
        last_health_check_at: Utils.formatUTCDateInLocalTime(carousel.last_health_check_at),
        ...(carousel.camera_id && {camera_id: carousel.camera_id}),
        statistics: (
          <>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Button
                color='rose'
                round
                sx={{
                  height: 10,
                  width: 100,
                }}
                onClick={() => showStateModal(carousel.id)}
              >
                Status
              </Button>
              <Button
                color='rose'
                round
                sx={{
                  height: 10,
                  width: 100,
                }}
                onClick={() => showLocationModal(carousel.id)}
              >
                Location
              </Button>
              {carousel?.alerts?.length > 0 &&
                <Button
                  color='rose'
                  round
                  sx={{
                    height: 10,
                    width: 100,
                  }}
                  onClick={() => {
                    setJsonObject(carousel.alerts);
                    setStatisticsTitle('Carousel Alerts');
                    setStatisticsModal(true);
                  }}
                >
                  Alerts
                </Button>
              }
            </div>
          </>
        ),
        qrCodeUrl: '/carousel/' + carousel.token,
      };
    });
  }

  const CustGridContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  }));

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  function SupportPanel({ currentRowData, closePanel }) {
    SupportPanel.propTypes = {
      currentRowData: PropTypes.shape({
        qrCodeUrl: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        nickname: PropTypes.string,
        hmiInUse: PropTypes.bool,
        alarmDetected: PropTypes.bool,
        hasGate: PropTypes.bool,
        gateState: PropTypes.string,
      }).isRequired,
      closePanel: PropTypes.func.isRequired,
    };

    return (
      <Box p={2}>

        <CustGridContainer>

          {/* Upper Widgets (Safe Ones) */}
          {currentRowData.hasGate && isParkingSupport &&
            <Box textAlign="center">
              <Tooltip title={currentRowData.gateState === 'CLOSED' ? 'Manually open the gate. The gate should automatically open during parking and retrieval, so this should only be used for testing or maintenance.' : 'Manually close the gate. The gate should automatically close during parking and retrieval, so this should only be used for testing or maintenance.'}>
                <IconButton onClick={() => {
                  onMoveGate(currentRowData.id, currentRowData.nickname, currentRowData.gateState === 'CLOSED', closePanel);
                }}>
                  {currentRowData.gateState === 'CLOSED' ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Tooltip>
              <div>{currentRowData.gateState === 'CLOSED' ? 'Open Gate' : 'Close Gate'}</div>
            </Box>
          }

          <Box textAlign="center">
            <Tooltip title="This checks for various error states seen in production, such as PLC network interruptions that leave parking sessions out of sync with the PLC state. If we identify such a condition, an alert icon will be displayed with an explanation and recommended action to fix it. These health checks occur automatically after any network interruptions with the PLC, but can also be run manually here.">
              <IconButton onClick={() => { runHealthCheck(currentRowData.id); }}>
                <AssignmentIcon/>
              </IconButton>
            </Tooltip>
            <div>Run Health Check</div>
          </Box>

          {isParkingSupport &&
            <Box textAlign="center">
              <Tooltip title="This should be used after maintenance, if the ground pallet is left out of sync with the PLC. Use the camera view to see which pallet is on the ground. This should match the pallet number in the first element of the locations array. If it doesn't, use this function to set the correct ground pallet.">
                <IconButton onClick={() => {
                  openGroundPalletModal(currentRowData, GroundPalletModalModes.INIT_GROUND_PALLET);
                }}>
                  <VerticalAlignBottomIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <div>Set Ground Pallet</div>
            </Box>
          }

          <Box textAlign="center">
            <Tooltip title="This reads the PIN in PLC memory. If the PLC is in use by mobile app, this is the PIN that is sent in the 'done' command.">
              <IconButton onClick={() => { onReadPin(currentRowData.id, currentRowData.nickname); }}>
                <MemoryIcon/>
              </IconButton>
            </Tooltip>
            <div>Read PLC PIN</div>
          </Box>

          {isSysAdmin &&
            <Box textAlign="center">
              <Tooltip title="Refresh cached carousel attributes, such as gate and tandem status">
                <IconButton onClick={() => {
                  onRefreshAttributes(currentRowData.id, currentRowData.nickname);
                }}>
                  <RepartitionIcon/>
                </IconButton>
              </Tooltip>
              <div>Refresh Attributes</div>
            </Box>
          }

          {/* isSysAdmin &&
            <Box textAlign="center">
              <Tooltip title="Show the carousel's static QR code, which is only used when there is no QR reader associated with the carousel. In this case, the QR code would be printed and mounted on the carousel for customers to scan from their mobile device.">
                <IconButton onClick={() => navigate(currentRowData.qrCodeUrl)}>
                  <LinkIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <div>Open QR</div>
            </Box>
          }

          {isSysAdmin &&
            <Box textAlign="center">
              <Tooltip title="Delete a carousel that has been entered in error or for testing purposes. Note that you cannot delete a carousel if there are any Devices, Pins or Parking Sessions associated with it. Devices and PINs can be removed from those tabs, but deleting parking sessions will require support.">
                <IconButton onClick={onDeleteCarousel(closePanel)}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
              <div>Delete Carousel</div>
            </Box>
          */}

          {/* Widget divider */}
          <Box gridColumn={isSmallScreen ? 'span 3' : 'span 4'}>
            <Divider />
          </Box>

          <Box gridColumn={isSmallScreen ? 'span 3' : 'span 4'} textAlign="center">
            <p style={{color: 'red', fontWeight: 'bold', fontSize: 16}}>WARNING: The actions below are potentially dangerous as they can cause the carousel to move. Proceed only after checking the camera.</p>
          </Box>

          {/* Lower Widgets (Dangerous Ones) */}
          {isParkingSupport &&
            <Box textAlign="center">
              <Tooltip
                title='Alarms are one type of alert reported by the PLC, indicated by the "sensors.isAlarmDetected" flag. These can sometimes be cleared by sending a command to the PLC that mimics the "Clear Alarms" function on the HMI, which is what this does.'
                placement={'right'}
              >
                <span>
                  <IconButton onClick={() => { onClearAlarm(currentRowData.id); }}>
                    <AlarmOffIcon/>
                  </IconButton>
                </span>
              </Tooltip>
              <div>Clear Alarm</div>
            </Box>
          }

          {isParkingSupport &&
            <Box textAlign="center">
              <Tooltip title='If the HMI is left in use after some maintenance, as can be seen in the Status data ("inUseByHmi" property), use this to reset the HMI and clear the in-use flags.'>
                <span>
                  <IconButton disabled={!currentRowData.hmiInUse} onClick={() => { onResetHmi(currentRowData.id, currentRowData.nickname); }}>
                    <ScreenLockLandscapeIcon/>
                  </IconButton>
                </span>
              </Tooltip>
              <div>Reset HMI</div>
            </Box>
          }

          {isParkingSupport &&
            <Box textAlign="center">
              <Tooltip title='Send a parking command, either PARK, RETRIEVE, REPARK or DONE. Note that after any manual parking operation, you must also send a DONE command to take the carousel out of "in use by mobile app" mode.'>
                <IconButton onClick={() => {
                  openSendParkingCommandModal(currentRowData.id, currentRowData.nickname);
                }}>
                  <DeveloperModeIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <div>Send Parking Command</div>
            </Box>
          }

          {isParkingSupport &&
            <Box textAlign="center">
              <Tooltip title="This should be used to call a specific pallet to the ground.">
                <IconButton onClick={() => {
                  openGroundPalletModal(currentRowData, GroundPalletModalModes.CALL_PALLET);
                }}>
                  <VerticalAlignBottomIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <div>Call Pallet To Ground</div>
            </Box>
          }

        </CustGridContainer>
      </Box>
    );
  }

  const actions = [{
      icon: 'autorenew',
      tooltip: 'Refresh',
      position: 'toolbar',
      onClick: onRefreshData,
    },
    {
      icon: () => (
        <ServiceStatusIcon
          status={carouselApiStatus}
          onRestart={handleRestartCarouselApi}
        />
      ),
      position: 'toolbar',
    },
    (rowData) => (
      {
        hidden: !rowData?.unhealthy_condition,
        icon: () => <WarningIcon style={{ color: 'red' }} />,
        tooltip: 'Critical Alert',
        onClick: (event, rowData) => handleOpenCriticalAlert(rowData),
      }
    ),
    ...(isSysAdmin ? [
      {
        icon: 'add',
        tooltip: 'Add Carousel',
        position: 'toolbar',
        onClick: () => {
          navigate('/carousels/add');
        },
      },
      {
        icon: 'edit',
        iconProps: { fontSize: 'small' },
        tooltip: 'Edit Carousel',
        onClick: (event, rowData) => {
          navigate(`/carousels/edit/${rowData.id}`);
        },
      }
    ] : []),
    ...(isParkingSupport || isSysAdmin ? [
      {
        icon: 'settings',
        tooltip: 'Open Support Panel',
        onClick: (event, rowData) => handleOpenSupportPanel(rowData),
      }
    ] : []),
  ];

  const tableCols = [
    { title: 'Name', field: 'nickname' },
    ...(isSysAdmin || isParkingSupport ? [{ title: 'Data', field: 'statistics' }] : []),
    { title: 'Description', field: 'description' },
    { title: 'Available Spaces', field: 'availableSpaces' },
    { title: 'Occupied Spaces', field: 'occupiedSpaces' },
    { title: 'Total Spaces', field: 'totalSpaces' },
    { title: 'Assigned Spaces', field: 'assignedSpaces' },
    { title: 'Reservation only?', render: rowData => rowData.is_reservation_only ? <Check /> : '' },
    { title: 'EV?', render: rowData => rowData.is_ev ? <Check /> : '' },
    { title: 'SUV?', render: rowData => rowData.is_suv ? <Check /> : '' },
    { title: 'Valet?', render: rowData => rowData.is_valet ? <Check /> : '' },
    { title: 'Tandem?', render: rowData => rowData.is_tandem ? <Check /> : '' },
    ...(isSysAdmin ? [{ title: 'API URL', field: 'ip_address' }] : []),
  ];

  if (inclGateColumn) {
    tableCols.splice(7, 0, { title: 'Gate', field: 'gateState' })
  }

  const SendParkingCommandModal = ({ open, onClose }) => {
    SendParkingCommandModal.propTypes = {
      open: PropTypes.bool.isRequired,
      onClose: PropTypes.func.isRequired,
    }

    const [commandOption, setCommandOption] = useState('');
    const [pinNumber, setPinNumber] = useState('');

    const handleChangeCommandOption = (event) => {
      setCommandOption(event.target.value);
      // Reset the park number if option is not PARK
      if (event.target.value !== 'PARK') {
        setPinNumber('');
      }
    };

    const handleSubmit = () => {
      onSendParkingCommand(commandOption, pinNumber);
      onClose();
    };

    const showPinField = commandOption === 'PARK' || commandOption === 'RETRIEVE';

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="command-modal-title"
        aria-describedby="command-modal-description"
        disablePortal
        sx={{
          zIndex: 1900,
        }}
      >
        <DialogTitle id="command-modal-title">
        <span style={{ display: 'block', textAlign: 'center', lineHeight: '1.2em' }}>
          Send Command
        </span>
        </DialogTitle>
        <DialogContent id="command-modal-description">
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <RadioGroup
              row
              aria-label="command-option"
              name="command-option-group"
              value={commandOption}
              onChange={handleChangeCommandOption}
              style={{ marginBottom: 25, display: 'flex', justifyContent: 'center' }}
            >
              <FormControlLabel value="PARK" control={<Radio />} label="PARK" />
              <FormControlLabel value="RETRIEVE" control={<Radio />} label="RETRIEVE" />
              <FormControlLabel value="REPARK" control={<Radio />} label="REPARK" />
              <FormControlLabel value="DONE" control={<Radio />} label="DONE" />
            </RadioGroup>
          </FormControl>

          {showPinField && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                id="pin-input"
                label="PIN"
                type="text"
                variant="outlined"
                value={pinNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  const re = /^[0-9\b]+$/; // 4 digits only
                  if (value === '' || (re.test(value) && value.length <= 4)) {
                    setPinNumber(value);
                  }
                }}
                inputProps={{
                  maxLength: 4,
                }}
                style={{ width: '50%' }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {/* Carousel Data */}
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={statisticsModal}
            scroll='body'
            TransitionComponent={Transition}
            keepMounted
            onClose={closeStatsModal}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title' className={classes.modalHeader}>
              <IconButton
                className={classes.modalCloseButton}
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={closeStatsModal}
                size='large'
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <div className={classes.modalTitle}>{statisticsTitle}</div>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              <JsonViewer value={jsonObject} />
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              {virtualCarBtn && <Button onClick={virtualCarBtn.handleClick} color='rose'>
                {virtualCarBtn.buttonText}
              </Button> }
            </DialogActions>
          </Dialog>

          {/* Modals for entering pallet number */}
          <Dialog
            classes={{
              root: classes.left,
              paper: classes.modal,
            }}
            disablePortal
            sx={{zIndex:1900, height: '80%'}}
            open={showGroundPalletModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeGroundPalletModal}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title'>
              <span style={{display: 'block', textAlign: 'center', lineHeight: '1.2em'}}>{groundPalletModalMode === GroundPalletModalModes.INIT_GROUND_PALLET ? 'Set Ground Pallet on' : 'Call Pallet to Ground on'}<br/>{modalCarouselNickname}</span>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              {groundPalletPosition !== null &&
                <FormControl style={{width: '100%'}}>
                  <RadioGroup
                    row
                    aria-labelledby="ground-pallet-position-label"
                    name="ground-pallet-position-group"
                    value={groundPalletPosition}
                    onChange={(e, value) => setGroundPalletPosition(value)}
                    style={{marginBottom: 25, display: 'block', textAlign: 'center'}}
                  >
                    <FormControlLabel value="entrance" control={<Radio />} label="Entrance" />
                    <FormControlLabel value="mirror" control={<Radio />} label="Mirror" />
                  </RadioGroup>
                </FormControl>
              }
              <TextField
                id='outlined-multiline-static'
                type={'number'}
                InputProps={{min: 0}}
                label='Pallet number 1 - X'
                variant='outlined'
                value={groundPalletNo}
                style={{display: 'block', textAlign: 'center'}}
                inputProps={{
                  maxLength: 2,
                }}
                InputLabelProps={{
                  style: { width: '100%', textAlign: 'left', paddingLeft: '56px' },
                }}
                onChange={changeGroundPalletNo}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              <Button
                onClick={closeGroundPalletModal}
              >
                Cancel
              </Button>
              <Button
                onClick={submitGroundPalletNo}
                color='primary'
              >
                {groundPalletModalMode === GroundPalletModalModes.INIT_GROUND_PALLET ? 'Set Ground Pallet' : 'Call Pallet to Ground'}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Critical alert modal */}
          <Dialog
            classes={{
              root: classes.left,
              paper: classes.modal,
            }}
            open={showCriticalAlertPanel}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCriticalAlert}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title'>
              <span style={{display: 'block', textAlign: 'center', lineHeight: '1.2em'}}>{currentRowData?.unhealthy_condition}</span>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              <Typography align="left" style={{fontSize: '1rem', textDecoration: 'underline'}}>
                Recommended action(s)
              </Typography>
              <ol>
                {currentRowData?.recommended_action && currentRowData.recommended_action.split('\\n').map((action, index) => (
                  <li key={index}>
                    {action}
                  </li>
                ))}
              </ol>
              <div style={{fontSize: 14}}>
                {currentRowData?.last_health_check_at}
              </div>
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              <Button
                onClick={handleCloseCriticalAlert}
              >
                Close
              </Button>
              <Button
                color={'success'}
                onClick={() => runHealthCheck(currentRowData.id, handleCloseCriticalAlert)}
              >
                Rerun health check
              </Button>
            </DialogActions>
          </Dialog>

          <SendParkingCommandModal open={showParkingCommandModal} onClose={closeSendParkingCommandModal}/>

          {currentRowData && isSupportPanelOpen && (
            <>
              <Dialog open={isSupportPanelOpen} onClose={handleCloseSupportPanel} maxWidth='sm' fullWidth disablePortal disableScrollLock
                      sx={{
                        height: '80%',
                      }}>
                <DialogTitle sx={{ textAlign: 'center' }}>Support Panel ({currentRowData.nickname})</DialogTitle>
                <SupportPanel currentRowData={currentRowData} closePanel={handleCloseSupportPanel} />
              </Dialog>
              {/* Camera view */}
              <Box sx={{
                zIndex: 5000,
                position: 'fixed',
                bottom: 20,
                left: 0,
                right: 0,
                margin: 'auto',
                border: '1px solid white',
                backgroundColor: currentRowData?.camera_id ? 'white' : 'black',
                width: '86%',
                maxWidth: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                aspectRatio: '1.9',

                '@media (min-width: 410px)': {
                  aspectRatio: '1.75',
                },

                '@media (min-width: 430px)': {
                  aspectRatio: '1.6',
                },

                '@media (min-width: 475px)': {
                  aspectRatio: '1.65',
                },

                '@media (min-width: 495px)': {
                  aspectRatio: '1.7',
                },

                '@media (min-width: 525px)': {
                  width: '88%',
                  aspectRatio: '1.8',
                },

                '@media (min-width: 545px)': {
                  width: '88%',
                  aspectRatio: '1.85',
                },

                '@media (min-width: 560px)': {
                  aspectRatio: '1.87',
                },

                '@media (min-width: 650px)': {
                  width: '90%',
                  aspectRatio: '1.92',
                },

                borderRadius: '4px',
              }}>
                {currentRowData?.camera_id ?
                  (cameraStreamToken ?
                  <img src={CameraService.getCameraUrl(currentRowData.camera_id, cameraStreamToken)} alt="camera"
                       style={{width: '100%', height: '100%'}}/> :
                    <Typography textAlign='center' fontSize='14px'>Loading camera stream...</Typography>)
                  : <Typography textAlign='center' fontSize='20px' color='white'>No Camera</Typography> }
              </Box>
            </>
          )}

          <MaterialTable
            title='Carousels'
            tableRef={tableRef}
            columns={tableCols}
            data={(query) =>
              new Promise((resolve, reject) => {
                CarouselService.getCarousels(query.pageSize, query.page + 1, query.search)
                  .then((res) => {
                    const carousels = res.results;
                    const foundGate = carousels.some(c => !!c.has_gate);
                    setInclGateColumn(foundGate);
                    const hasApiAlert = carousels.some(c => CarouselService.carouselHasApiAlert(c));
                    setCarouselApiStatus(hasApiAlert ? 'unhealthy' : 'healthy');
                    resolve({
                      data: transform(carousels),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(err => {
                    console.error('Error fetching carousels', err);
                    setCarouselApiStatus('unhealthy');
                    reject(err);
                  });
              })
            }
            actions={actions}
            components={{
              Toolbar: (props) => (
                <div style={{alignItems: 'center', paddingRight: '0%'}}>
                  <CardHeader color='primary' icon>
                    <CardIcon color='rose'>
                      <ListAltIcon/>
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: 0,
              debounceInterval: 500,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
